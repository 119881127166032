import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import Block from "@insightfulscience/atomic-react/Block";
import Image from "@insightfulscience/atomic-react/Image";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import { useLocale } from "@insightfulscience/smart-react/i18";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

const iconPrime = "/images/geneious/logo/icon-prime.svg";
const iconBiologics = "/images/geneious/logo/icon-biologics.svg";

export default () => {
	const [t] = useLocale("header");

	return (
		<MegaMenu>
			<MegaMenuCol
				md={12}
				lg={5}
				utility={{
					lg: {
						border: {
							right: {
								width: 1,
								color: "black--20",
							},
						},
					},
				}}
			>
				<Block
					utility={{ display: "flex", align: { items: "center" } }}
					sx={{ fontSize: "xl", fontWeight: "semi" }}
					spacing={{ pl: 1 }}
				>
					<NextLink color="black" hoverColor="3" href={routes.header.products.prime.index}>
						<Image src={iconPrime} spacing={{ mr: 2 }} />
						{t("PRODUCTS_LIST.PRIME_TITLE")}
					</NextLink>
				</Block>
				<Block sx={{ fontSize: "m", fontColor: "black--46" }} spacing={{ my: 2, pl: 1 }}>
					{t("PRODUCTS_LIST.PRIME_DESCR")}
				</Block>
				<Block>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.products.prime.sequenceAnalysis}
						>
							{t("PRODUCTS_LIST.PRIME_SEQUENCE")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink Component={MegaMenuItem.Link} k href={routes.header.products.prime.cloning}>
							{t("PRODUCTS_LIST.PRIME_CLONING")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.prime.automation}
						>
							{t("PRODUCTS_LIST.PRIME_AUTOMATION")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.prime.allFeatures}
						>
							{t("PRODUCTS_LIST.PRIME_EXPLORE")}
						</NextLink>
					</MegaMenuItem>
				</Block>
			</MegaMenuCol>
			<MegaMenuCol md={12} lg={5}>
				<Block
					utility={{ display: "flex", align: { items: "center" } }}
					sx={{ fontSize: "xl", fontWeight: "semi" }}
					spacing={{ pl: 1 }}
				>
					<NextLink color="black" hoverColor="3" href={routes.header.products.biologics.index}>
						<Image src={iconBiologics} spacing={{ mr: 2 }} />
						{t("PRODUCTS_LIST.BIOLOGICS_TITLE")}
					</NextLink>
				</Block>
				<Block sx={{ fontSize: "m", fontColor: "black--46" }} spacing={{ my: 2, pl: 1 }}>
					{t("PRODUCTS_LIST.BIOLOGICS_DESCR")}
				</Block>
				<Block>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.biologics.antibodyDiscovery}
						>
							{t("PRODUCTS_LIST.BIOLOGICS_DISCOVERY")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.biologics.candidate}
						>
							{t("PRODUCTS_LIST.BIOLOGICS_CANDIDATE")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.biologics.security}
						>
							{t("PRODUCTS_LIST.BIOLOGICS_SECURITY")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							k
							href={routes.header.products.biologics.allFeatures}
						>
							{t("PRODUCTS_LIST.BIOLOGICS_EXPLORE")}
						</NextLink>
					</MegaMenuItem>
				</Block>
			</MegaMenuCol>
		</MegaMenu>
	);
};
