import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import ButtonTheme from "@insightfulscience/atomic-react/ButtonTheme";
import Icon from "@insightfulscience/atomic-react/Icon";
import Image from "@insightfulscience/atomic-react/Image";
import { useLocale } from "@insightfulscience/smart-react/i18";
import routes from "../routes";

const platformImg = "/images/dotmatics/lab-archives/top-bar/platform.png";

export const PlatformDropDown = () => {
	const [t] = useLocale("header");

	return (
		<>
			<MegaMenu utility={{ justify: "start" }}>
				<MegaMenuCol md={8}>
					<MegaMenuItem>
						<Paragraph sx={{ fontSize: "l", fontColor: "white", fontWeight: "bold" }}>
							{t("TOPBAR.platform.TITLE")}
						</Paragraph>
						<Paragraph sx={{ fontSize: "s", fontColor: "white" }}>
							{t("TOPBAR.platform.DESC")}
						</Paragraph>
						<ButtonTheme.Link
							href={routes.external.platform}
							spacing={{ px: 5, py: 3, mt: 4 }}
							styleType="topbar"
						>
							{t("TOPBAR.platform.BTN")}
							<Icon spacing={{ ml: 4 }} name="link-ext" />
						</ButtonTheme.Link>
					</MegaMenuItem>
				</MegaMenuCol>
			</MegaMenu>
			<Image src={platformImg} className="position-absolute top-0 right-0" />
		</>
	);
};
