import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import Block from "@insightfulscience/atomic-react/Block";
import Image from "@insightfulscience/atomic-react/Image";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import { useLocale } from "@insightfulscience/smart-react/i18";
import Link from "@insightfulscience/atomic-react/Link";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

const iconPrime = "/images/geneious/logo/icon-prime.svg";
const iconBiologics = "/images/geneious/logo/icon-biologics.svg";

export default () => {
	const [t] = useLocale("header");

	return (
		<MegaMenu>
			<MegaMenuCol
				md={12}
				lg={5}
				utility={{
					lg: {
						border: {
							right: {
								width: 1,
								color: "black--20",
							},
						},
					},
				}}
			>
				<Block
					utility={{ display: "flex", align: { items: "center" } }}
					sx={{ fontSize: "xl", fontWeight: "semi" }}
					spacing={{ pl: 1 }}
				>
					<NextLink
						color="black"
						hoverColor="3"
						href={routes.header.resources.primeResources.index}
					>
						<Image src={iconPrime} spacing={{ mr: 2, pl: 1 }} />
						{t("RESOURCES_LIST.PRIME_TITLE")}
					</NextLink>
				</Block>
				<Block>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.primeResources.gettingStarted}
						>
							{t("RESOURCES_LIST.PRIME_STARTED")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.primeResources.academy}
						>
							{t("RESOURCES_LIST.PRIME_ACADEMY")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.resources.primeResources.userManual}>
							{t("RESOURCES_LIST.PRIME_MANUAL")}
						</MegaMenuItem.Link>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.resources.primeResources.knowledgeBase}>
							{t("RESOURCES_LIST.PRIME_KNOWLEDGE")}
						</MegaMenuItem.Link>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.primeResources.download}
						>
							{t("RESOURCES_LIST.PRIME_DOWNLOAD")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.primeResources.installPlugins}
						>
							{t("RESOURCES_LIST.PRIME_PLUGINS")}
						</NextLink>
					</MegaMenuItem>
				</Block>
			</MegaMenuCol>
			<MegaMenuCol md={12} lg={5}>
				<Block
					utility={{ display: "flex", align: { items: "center" } }}
					sx={{ fontSize: "xl", fontWeight: "semi" }}
					spacing={{ pl: 1 }}
				>
					<Link
						color="black"
						hoverColor="3"
						href={routes.header.resources.biologicsResources.index}
					>
						<Image src={iconBiologics} spacing={{ mr: 2, pl: 1 }} />
						{t("RESOURCES_LIST.BIOLOGICS_TITLE")}
					</Link>
				</Block>
				<Block>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.resources.biologicsResources.gettingStarted}>
							{t("RESOURCES_LIST.BIOLOGICS_STARTED")}
						</MegaMenuItem.Link>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.biologicsResources.academy}
						>
							{t("RESOURCES_LIST.BIOLOGICS_ACADEMY")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.resources.biologicsResources.userGuide}>
							{t("RESOURCES_LIST.BIOLOGICS_GUIDE")}
						</MegaMenuItem.Link>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.biologicsResources.dataSecurity}
						>
							{t("RESOURCES_LIST.BIOLOGICS_SECURITY")}
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink
							Component={MegaMenuItem.Link}
							href={routes.header.resources.biologicsResources.requestDemo}
						>
							{t("RESOURCES_LIST.BIOLOGICS_DEMO")}
						</NextLink>
					</MegaMenuItem>
				</Block>
			</MegaMenuCol>
		</MegaMenu>
	);
};
