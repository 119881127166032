import React from "react";

import TopBar from "@insightfulscience/atomic-react/TopBar";
import TopBarLogo from "@insightfulscience/atomic-react/TopBar/Logo";
import Link from "@insightfulscience/atomic-react/Link";
import Image from "@insightfulscience/atomic-react/Image";
import TopBarDropdown from "@insightfulscience/atomic-react/TopBar/Dropdown";
import TopBarNav from "@insightfulscience/atomic-react/TopBar/Nav";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { PlatformDropDown } from "./PlatformDropDown";
import { ProductsTopBar } from "./ProductsTopBar";

const logoImg = "/images/common/dotmatics-logo-white.svg";

export const HeaderTopBar = () => {
	const [t] = useLocale("header");

	return (
		<TopBar>
			<TopBarLogo>
				<Link href="https://www.dotmatics.com/">
					<Image src={logoImg} />
				</Link>
			</TopBarLogo>
			<TopBarNav>
				<TopBarDropdown isMegaMenu>
					<TopBarDropdown.Title
						triangle
						triangleProps={{ color: "dotmatics-2", isOpenColor: "dotmatics-2" }}
					>
						{t("TOPBAR.PLATFORM_PRODUCTS")}
					</TopBarDropdown.Title>
					<TopBarDropdown.Body
						utility={{
							bgColor: "dotmatics-6",
							border: {
								top: { width: 1, color: "white--20" },
								bottom: { width: 1, color: "white--20" },
							},
						}}
						spacing={{ my: 2 }}
					>
						<PlatformDropDown />
						<ProductsTopBar />
					</TopBarDropdown.Body>
				</TopBarDropdown>
			</TopBarNav>
		</TopBar>
	);
};
