import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import MegaMenuBlockLink from "@insightfulscience/atomic-react/MegaMenu/MegaMenuBlockLink";
import ButtonTheme from "@insightfulscience/atomic-react/ButtonTheme";
import Icon from "@insightfulscience/atomic-react/Icon";
import { useLocale } from "@insightfulscience/smart-react/i18";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

const prismLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Prism.svg";
const geneiousLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Geneious.svg";
const snapgeneLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-SnapGene.svg";
const labArchivesLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-LabArchives.svg";
const proteinMetricLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Protein-Metrics.svg";
const omiqLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-OMIQ.svg";
const fcsExpressLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-FCS-Express.svg";
const nQueryLogo = "/images/dotmatics/lab-archives/top-bar/PreNav-Logo-nQuery.svg";

export const ProductsTopBar = () => {
	const [t] = useLocale("header");
	const utmSiteName = "geneious";

	return (
		<MegaMenu spacing={{ py: 5 }}>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.prism,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={prismLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.PRISM")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<NextLink Component={MegaMenuBlockLink} href="/" onClick={e => e.preventDefault()}>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={geneiousLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.GENEIOUS")}
					</MegaMenuBlockLink.Description>
				</NextLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.snapgene,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={snapgeneLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.SNAPGENE")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.labArchives,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={labArchivesLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.LABARCHIVES")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.proteinMetrics,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={proteinMetricLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.PROTEINMETRIC")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.omiq,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={omiqLogo} />
					<MegaMenuBlockLink.Description>{t("TOPBAR.products.OMIQ")}</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.fcsExpress,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={fcsExpressLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.FCSEXPRESS")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink
					href={t("TOPBAR.products.UTM_LINK", {
						mainLink: routes.external.products.nQuery,
						utmSiteName,
					})}
				>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src={nQueryLogo} />
					<MegaMenuBlockLink.Description>
						{t("TOPBAR.products.NQUERY")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>

			<MegaMenuCol sm={12} md={12} lg={12}>
				<ButtonTheme.Link
					href={routes.external.portfolio}
					spacing={{ px: 5, py: 3, mt: 4, mb: 8 }}
					styleType="topbar"
				>
					{t("TOPBAR.products.BTN")}
					<Icon spacing={{ ml: 4 }} name="link-ext" />
				</ButtonTheme.Link>
			</MegaMenuCol>
		</MegaMenu>
	);
};
